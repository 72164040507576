import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import { Observable } from "rxjs";
import { CookieService } from "ngx-cookie-service";
import { tap } from "rxjs/operators";

@Injectable({providedIn: "root"})
export default class AuthService {
  constructor(private http: HttpClient, private cookieService: CookieService) {
  }
  auth(): Observable<{token: string}> {
    return this.http.post('https://api.ilovepdf.com/v1/auth', {
      public_key: 'project_public_90b85fefe126226e52c6f1a8bf1c7222_Yv0Nvecec6b743f391fdbbb5ae360ce7c13ae',
      }).pipe(tap((response: any) => {
        const expiration = new Date();
        expiration.setHours(expiration.getHours() + 1);
        this.cookieService.set('pdf_token', response.token, { expires: expiration });
    }));
  }
}
