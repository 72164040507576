import { Component, ViewChild, Renderer2, ElementRef } from '@angular/core';
import { UploadService } from '@services/upload.service';
import { Subscription } from 'rxjs';
import { Router, NavigationEnd } from '@angular/router';
import { environment } from '@environments/environment';
import { IFile } from '@interfaces/IFile'
import SplitService from './split.service';

@Component({
  selector: 'app-split',
  templateUrl: './split.component.html',
  styleUrls: ['./split.component.scss']
})
export class SplitComponent {
  @ViewChild("fileUpload", { static: false }) fileUpload: ElementRef
  @ViewChild('fileName', { static: false }) fileName: ElementRef

  private _progress: string = 'start'
  private progress_list = ['start', 'loading', 'cancel', 'multi', 'error', 'alert', 'finished']
  files: IFile[] = [];
  inProgress: boolean = false;
  intervalRef = [];
  extension = environment.extension;
  sub: Array<Subscription> = [];

  constructor(
    private router: Router,
    private splitService: SplitService
  ) { }

  public ngOnInit(): void {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  doLoading() {
    if (this.files.length > 1) {
      this.clearScreen('multi')
    } else if (this.files[0].type != 'application/pdf') {
      this.clearScreen('alert')
    } else {
      this.progress = 'loading'
    
    }
  }

  reloadScreen() {
    this.clearScreen('start')
  }

  uploadFiles() {
    if (!this.inProgress) {
      this.splitService.mainProcessPdf(this.files).subscribe(v => {
        let dataType = v.file.type;
        let binaryData: Blob[] = [];
        binaryData.push(v.file);
        let downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: dataType }));
        downloadLink.setAttribute('download', v.name);
        document.body.appendChild(downloadLink);
        downloadLink.click();
      });
    }
  }



  clearScreen(progress: string) {
    if (progress && this.progress_list.includes(progress)) {
      this.progress = progress
    }

    this.files = [];
    this.fileUpload.nativeElement.value = null;
    Object.values(this.sub).forEach((value: Subscription, index) => {
      value.unsubscribe()
    })
    this.sub = []

    while (this.intervalRef.length) {
      this.intervalRef.forEach(element => clearInterval(element));
      this.intervalRef.pop()
    }

    this.inProgress = false;
  }

  onDrop(event) {
    if (event.addedFiles && event.addedFiles.length > 0) {
      this.files.push(...event.addedFiles);
      this.doLoading();
    }
  }

  onClick() {
    const fileUpload = this.fileUpload.nativeElement;
    fileUpload.click();
    fileUpload.onchange = () => {
      if (fileUpload && fileUpload.files.length > 0) {
        this.files.push(...fileUpload.files);
        this.doLoading();
      }
    }
  }

  set progress(val: string) {
    this._progress = val;
  }

  get progress(): string {
    return this._progress;
  }


}