import { Injectable } from "@angular/core";
import { Observable, forkJoin, of } from "rxjs";
import { switchMap } from "rxjs/operators";
import PdfConvertHttpService from "../../services/pdf-convert-http.service";

@Injectable({ providedIn: "root" })
export default class SplitService {
    constructor(
        private pdfConvertApi: PdfConvertHttpService,
    ) { }

    public mainProcessPdf(files: any): Observable<any> {

        return this.pdfConvertApi.start('split').pipe(switchMap((startResponse) => {
            const params =  { fixed_range: 1 };
            const server = startResponse.server;
            const task = startResponse.task;
            const uploadList: Observable<any>[] = [];
            const fileDataList: any[] = [];

            const prepareData = (file: any) => {
                const formData = new FormData();
                formData.append('task', task);
                formData.append('file', file, file.name);
                uploadList.push(this.pdfConvertApi.upload(formData, server));
                fileDataList.push({ name: file.name, params: file.params });
            }


            files.forEach(prepareData);

            return forkJoin(uploadList).pipe(switchMap((uploadResponse: any[]) => {

                const filesToProcess = uploadResponse.map((el, idx) => { return { server_filename: el.server_filename, filename: fileDataList[idx].name, ...fileDataList[idx].params } })
                return this.pdfConvertApi.process(server, task, 'split', filesToProcess, params).pipe(switchMap(
                    (processedData) => {

                        return this.pdfConvertApi.download(task, server).pipe(switchMap((blob) => {

                            return of({ name: processedData.download_filename, file: blob, size: processedData.filesize });
                        }))
                    }));
            }));
        }));
    }
}
