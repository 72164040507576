
  <div class="hero-container">
    <div class="inner-block flex-container column align-center">
      <h1 class="main-title">One place for all your PDF tools </h1>

      <p class="main-subtitle-2">Choose the tool you need</p>
      <div class="tools-available">

        <div class=" top-blocks">
          <a href="/convert" [routerLink]="['/convert']" class="main-block">
    
    
            <img class="m-block-non-hover" src="../../../assets/images/m-convert.png" alt="">
            <img class="m-block-hover" src="../../../assets/images/m-convert-hover.png" alt="">
            <div class="text-block-main">
              <p class="text-block-main__title">
                Convert PDF
              </p>
              <p class="text-block-main__subtitle">
                Create a PDF from  various file types.
              </p>
            </div>
          </a>
          <a href="/merge" [routerLink]="['/merge']" class="main-block">
    
    
            
            <img class="m-block-non-hover" src="../../../assets/images/m-merge.png" alt="">
            <img class="m-block-hover" src="../../../assets/images/m-merge-hover.png" alt="">
            <div class="text-block-main">
              <p class="text-block-main__title">
                PDF to Word
              </p>
              <p class="text-block-main__subtitle">
                Converts PDFs into editable Word files for easy modification.
              </p>
            </div>
          </a>
          <a href="/compress" [routerLink]="['/compress']" class="main-block">
    
    
            <img class="m-block-non-hover" src="../../../assets/images/m-compress.png" alt="">
            <img class="m-block-hover" src="../../../assets/images/m-compress-hover.png" alt="">
            <div class="text-block-main">
              <p class="text-block-main__title">
                Word to PDF
              </p>
              <p class="text-block-main__subtitle">
                Converts Words into editable PDF files for easy modification.
              </p>
            </div>
          </a>
    
        </div>
  
      </div>
    </div>
  </div>
 
