import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ConvertComponent } from '@components/convert/convert.component';
import { MergeComponent } from '@components/merge/merge.component';
import { OptimizeComponent } from '@components/optimize/optimize.component';
import { MainComponent } from '@components/main/main.component';
import { NotFoundComponent } from '@components/not-found/not-found.component';
import { SplitComponent } from '@app/components/split/split.component';


const routes: Routes = [
  { path: '', component: MainComponent },
  { path: 'merge', component: MergeComponent },
  { path: 'compress', component: OptimizeComponent },
  { path: 'convert', component: ConvertComponent },
  { path: 'split', component: SplitComponent },
  { path: '**', component: NotFoundComponent },
];
@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: true
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
