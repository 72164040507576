import { Component, ViewChild, ElementRef } from '@angular/core';
import { UploadService } from  '@services/upload.service';
import { Subscription } from 'rxjs';
import { Router, NavigationEnd } from '@angular/router';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent {

  content = {

    stepOneTitle: 'STEP 01',
    stepOneText: 'Upload your PDF files',
    stepTwoTitle: 'STEP 02',
    stepTwoText: 'Edit your PDF files',
    stepThreeTitle: 'STEP 03',
    stepThreeText: 'Download your file',
    homeAboutTitle: 'How It Works?',
    aboutOneTitle: 'Upload',
    aboutOneText: 'Step 1',
    aboutTwoTitle: 'Process',
    aboutTwoText: 'Step 2',
    aboutThreeTitle: 'Download',
    aboutThreeText: 'Step 3',
 
  }

  constructor(
    private router:Router
  ) {}


  
}