import { Injectable } from "@angular/core";
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Observable } from "rxjs";
import AuthService from "./auth.service";
import { CookieService } from "ngx-cookie-service";
import { switchMap } from "rxjs/operators";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private auth: AuthService, private cookieService: CookieService) {}

  intercept(req: HttpRequest<any>,
    next: HttpHandler): Observable<HttpEvent<any>> {
    const authRequiredRequestsUrls = ['start', 'upload', 'process', 'download'];
    if (authRequiredRequestsUrls.some(url => req.url.includes(url))) {
      const idToken = this.cookieService.get("pdf_token");
      if (idToken) {
        const cloned = this.cloneWithHeaders(req, idToken);
        return next.handle(cloned);
      } else {
        return this.auth.auth().pipe(switchMap((val) => {
          const cloned = this.cloneWithHeaders(req, val.token);
          return next.handle(cloned);
        }))
      }
    } else {
      return next.handle(req);
    }

  }
  private cloneWithHeaders(req: HttpRequest<any>, token: string): HttpRequest<any> {
    const cloned = req.clone({
      headers: req.headers.set("Authorization", "Bearer " + token)});
    return cloned;
  }

}

