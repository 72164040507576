import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";



@Injectable({providedIn: "root"})
export default class PdfConvertHttpService {
  
  constructor(private http: HttpClient) {
  }

  start(tool: any): Observable<any> {
    return this.http.get<any>(`https://api.ilovepdf.com/v1/start/${tool}`);
  }

  upload(formData: FormData, server: string): Observable<any> {
    return this.http.post<any>(`https://${server}/v1/upload`, formData, { reportProgress: true, observe: "body"});
  }

  process(server: string, task: string, tool: any, files: any[], params: any): Observable<any> {
    return this.http.post<any>(`https://${server}/v1/process`, { task: task, tool: tool, files, ...params});
  }

  download(task: string, server: string): Observable<Blob> {
    return this.http.get<Blob>(`https://${server}/v1/download/${task}`, {responseType: 'blob' as 'json', reportProgress: true, observe: 'body'});
  }

  googleUserInfo(access_token: string): Observable<any> {
    return this.http.get<any>('https://content.googleapis.com/oauth2/v2/userinfo', { headers: { authorization: 'Bearer ' + access_token }} );
  }

  getFileByLink(link: string, access_token?: string): Observable<Blob> {
    return this.http.get<Blob>(link, { responseType: 'blob' as 'json', reportProgress: true, observe: "body", headers: !!access_token ?  {authorization: 'Bearer ' + access_token } : {}});
  }

  

}
